<template>
  <section class="equipment-alarm-user-form">
    <div class="equipment-alarm-user-title">
      <span>{{ title }}</span>
    </div>
    <form autocomplete="false" v-on:submit.prevent="validate">
      <input
        autocomplete="false"
        name="hidden"
        type="text"
        style="display: none"
      />
      <div class="row">
        <div
          class="col-sm-12"
          v-if="requires == 'both' || requires == 'remark'"
        >
          <div class="form-group has-feedback">
            <textarea
              class="form-control"
              v-bind:placeholder="$t('remark')"
              required
              v-model="remark"
            ></textarea>
            <span class="fa fa-pencil text-gray form-control-feedback"></span>
          </div>
        </div>
        <div class="col-sm-4" v-if="requires == 'both' || requires == 'user'">
          <div class="form-group has-feedback">
            <input
              type="text"
              class="form-control"
              :class="{ error: status == 'invalid' }"
              placeholder="Email"
              required
              autocomplete="off"
              v-model="user"
            />
            <span class="fa fa-user text-gray form-control-feedback"></span>
          </div>
        </div>
        <div class="col-sm-4" v-if="requires == 'both' || requires == 'user'">
          <div class="form-group has-feedback">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              required
              autocomplete="off"
              v-model="password"
            />
            <span class="fa fa-lock text-gray form-control-feedback"></span>
          </div>
        </div>

        <div class="col-sm-4" v-if="requires == 'both' || requires == 'user'">
          <button
            v-if="status == 'invalid'"
            class="btn btn-default form-control"
          >
            <span class="">
              <b><i class="fa fa-check"></i> {{ $t("confirm") }}</b>
            </span>
          </button>

          <button v-else-if="status == 'valid'" class="btn form-control">
            <span class="text-success">
              <b><i class="fa fa-check-circle-o"></i> {{ $t("confirmed") }}</b>
            </span>
          </button>

          <button v-else class="btn form-control" disabled>
            <span class="">
              <b
                ><i class="fa fa-refresh fa-spin"></i> {{ $t("validating") }}</b
              >
            </span>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
import AuthService from "../services/auth";
export default {
  name: "EquipmentAlarmUserForm",
  data: function() {
    return {
      user: "",
      password: "",
      remark: "",
      status: "invalid"
    };
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Execução"
    },
    requires: {
      type: String,
      required: false,
      default: "none" // none || user || remark || both
    },
    value: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {},
  watch: {
    remark: function(n, o) {
      let self = this;
      self.status = "invalid";
      self.$emit("input", null);
      if (self.requires == "remark") {
        if (n) {
          self.$emit("input", { remark: n });
        }
      }
    },
    user: function(n, o) {
      let self = this;
      self.status = "invalid";
      self.$emit("input", null);
    },
    password: function(n, o) {
      let self = this;
      self.status = "invalid";
      self.$emit("input", null);
    }
  },
  methods: {
    validate() {
      let self = this;
      self.status = "busy";
      let uid = (this.$store.getters["user/loggedUser"] || {})?.id || "";
      if (!uid) {
        self.$emit("input", null);
        return;
      }
      let auth = new AuthService();
      auth
        .validateCredentials(uid, this.user, this.password)
        .then((d) => {
          self.status = d != null ? "valid" : "invalid";
          if (self.status == "valid") {
            self.$emit("input", {
              user: self.user,
              remark: self.remark
            });
          } else {
            self.$emit("input", null);
          }
        })
        .catch(() => {
          self.status = "invalid";
          self.$emit("input", null);
        });
    }
  }
};
</script>
<style scoped>
.equipment-alarm-user-form {
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 10px;
  margin: 5px -5px 20px -5px;
}
.equipment-alarm-user-title {
  margin-top: -1.5em;
  margin-bottom: 1em;
}
.equipment-alarm-user-title > span {
  padding: 0 5px;
  background-color: #fafafa;
  border-radius: 3px;
  color: gray;
  font-weight: 600;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
